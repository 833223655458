import React, { FunctionComponent } from 'react';
import { FlexBox } from '../../../utils/styledComponents';
import { Box } from '@mui/system';
import { IApplicant } from '../../../utils/models';
import CommonButton from '../../common/CommonButton';
import { formatDateDMY } from '../../../utils/utils';
import {
  // Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { acterioTheme, naturalPallete, primary } from '../../../theme';
import { TypographyGrey } from './ApplicationsListView';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router';

interface ApplicationListElementProps {
  applicant: IApplicant;
}

const ApplicationListElement: FunctionComponent<ApplicationListElementProps> = (
  props,
) => {
  const { applicant } = props;
  const navigate = useNavigate();

  return (
    <FlexBox
      className={'between'}
      sx={{
        width: '100%',
        paddingY: '1rem',
        borderBottom: `1px solid ${naturalPallete.natural6}`,
      }}>
      <FlexBox
        sx={{
          gap: '1.875rem',
        }}>
        <FlexBox
          sx={{
            width: '14.688rem',
            paddingLeft: '1.875rem',
          }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Box
                  // Change to checkbox once ready and remove width
                  sx={{
                    color: acterioTheme.dark,
                    '&.Mui-checked': {
                      color: acterioTheme.dark,
                    },
                    width: '0.5rem',
                  }}
                  onChange={() => console.log('checked')}
                />
              }
              label={applicant.companyName}
            />
          </FormGroup>
        </FlexBox>
        <Box sx={{ width: '20.313rem' }}>
          <TypographyGrey>{applicant.email}</TypographyGrey>
        </Box>

        <Box
          sx={{
            width: '12.5rem',
          }}>
          <Typography
            sx={{
              fontSize: '0.8rem',
              fontWeight: '400',
              paddingX: '0.75rem',
              paddingY: '0.125rem',
              color: `${
                applicant.applicationStatus.color
                  ? applicant.applicationStatus.color
                  : primary.natural2
              }`,
              borderRadius: '6px',
              backgroundColor: primary.whiteHighlight,
              display: 'inline-block',
            }}>
            {applicant.applicationStatus.name}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '6rem',
          }}>
          <TypographyGrey>
            {formatDateDMY(new Date(applicant.submitDate))}
          </TypographyGrey>
        </Box>
      </FlexBox>
      <CommonButton
        onClick={() => navigate(`${applicant.id}`)}
        icon={<VisibilityIcon />}
        text={'Full application'}
      />
    </FlexBox>
  );
};

export default ApplicationListElement;
