import React, { FunctionComponent } from 'react';
import { FlexBox } from '../../../utils/styledComponents';
import ApplicationListElement from './ApplicationListElement';
import { Box, styled } from '@mui/system';
import { Typography } from '@mui/material';
import { naturalPallete } from '../../../theme';
import { IApplicant } from '../../../utils/models';
import { useApplicationContext } from 'services/contexts/Applications/ApplicationsContext';

export const TypographyGrey = styled(Typography)({
  color: naturalPallete.natural3,
});

interface IApplicationListProps {
  applicants: IApplicant[];
}

const ApplicationsListView: FunctionComponent<IApplicationListProps> = (
  props,
) => {
  const { applicants } = props;

  const { applicationOverviewData } = useApplicationContext();

  function renderListView() {
    return (
      <Box
        sx={{
          padding: '0.75rem',
          border: '1px solid rgba(204, 204, 204, 0.2)',
          borderRadius: '12px',
        }}>
        <FlexBox
          sx={{
            borderBottom: `1px solid ${naturalPallete.natural6}`,
            paddingY: '1.25rem',
            gap: '1.875rem',
          }}>
          <FlexBox
            sx={{
              width: '14.688rem',
            }}>
            <TypographyGrey>COMPANY NAME</TypographyGrey>
          </FlexBox>
          <FlexBox
            sx={{
              width: '20.313rem',
            }}>
            <TypographyGrey>CONTACT EMAIL</TypographyGrey>
          </FlexBox>
          <FlexBox
            sx={{
              width: '12.5rem',
            }}>
            <TypographyGrey>STATUS</TypographyGrey>
          </FlexBox>
          <FlexBox
            sx={{
              width: '8rem',
            }}>
            <TypographyGrey>SUBMITTED</TypographyGrey>
          </FlexBox>
        </FlexBox>

        <FlexBox
          sx={{
            minHeight: '100%',
            gap: '0.5rem',
            flexDirection: 'column',
          }}>
          {applicants.map((applicant) => {
            return (
              <ApplicationListElement
                key={applicant.id}
                applicant={applicant}
              />
            );
          })}
        </FlexBox>
      </Box>
    );
  }
  if (!applicationOverviewData) return null;
  return <Box>{renderListView()}</Box>;
};

export default ApplicationsListView;
