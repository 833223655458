import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Tooltip,
  useTheme,
} from '@mui/material';
import {
  CompanyProfileFieldType,
  ICompanyField,
  ICompanyProfile,
  ITag,
} from 'utils/models';
import InputField from '../../common/InputField';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';
import TagsField from '../../common/Tags/TagsField';
import { naturalPallete, primary } from 'theme';
import { FlexBox } from 'utils/styledComponents';
import AccessLevelTagCard from 'components/common/AccessLevelTagCard';
import { IconPicker } from 'components/common/IconPicker';
import { useParams } from 'react-router';
import UploadImage from 'components/common/UploadImage';
import { MoreVert } from '@mui/icons-material';
import CompanyContact from '../CompanyContact';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import InformationModal from 'components/common/Modal/InformationModal';
import CompanyEditSocialMediaAndContacts from './CompanyEditSocialMediaAndContacts';
import {
  COMPANY_LOGO_ROUTE,
  deleteData,
  putData,
  COMPANY_BANNER_ROUTE,
} from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import Loading from 'components/common/Loading';

interface ICompanyEditHeader {}

const CompanyEditHeader: FunctionComponent<ICompanyEditHeader> = (props) => {
  const {
    companyProfile,
    setCompanyProfile,
    checkIfInfoIsMissing,
    updateCompanyField,
    removeSelectedTag,
    setEditedLogo,
    updateCompanyTagGroup,
  } = useCompanyContext();

  const { addAlert } = useContext(AlertContext);

  const { ecosystemName } = useParams();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLogoLoading, setIsLogoLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [copyCompanyProfiles, setCopyCompanyProfiles] =
    useState<ICompanyProfile>(JSON.parse(JSON.stringify(companyProfile)));

  async function uploadNewCompanyLogo(file: any) {
    setIsLogoLoading(true);

    try {
      const formData = new FormData();

      formData.append('companyLogo', file);

      const data = await putData(
        COMPANY_LOGO_ROUTE,
        [
          { name: 'ecosystemName', value: ecosystemName },
          {
            name: 'companyName',
            value: companyProfile!.name,
          },
        ],
        formData,
      );

      updateCompanyLogo(data);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Logo updated',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLogoLoading(false);
  }

  async function uploadNewCompanyBanner(file: any) {
    setIsLoading(true);

    try {
      const formData = new FormData();

      formData.append('companyBanner', file);

      const data = await putData(
        COMPANY_BANNER_ROUTE,
        [
          { name: 'ecosystemName', value: ecosystemName },
          {
            name: 'companyName',
            value: companyProfile!.name,
          },
        ],
        formData,
      );

      updateCompanyBanner(data);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Banner updated',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  async function deleteLogo() {
    setIsLogoLoading(true);

    try {
      const data = await deleteData(COMPANY_LOGO_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'companyName', value: companyProfile!.name },
      ]);

      // updateCompanyLogo(data);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Logo Deleted',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLogoLoading(false);
  }

  async function deleteBanner() {
    setIsLoading(true);

    try {
      const data = await deleteData(COMPANY_BANNER_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'companyName', value: companyProfile!.name },
      ]);

      updateCompanyBanner(data);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Banner Deleted',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function updateCompanyBanner(updatedBanner: string) {
    setCompanyProfile((prevProfile) => {
      if (!prevProfile) return null;
      const updatedProfile = {
        ...prevProfile,
        bannerUrl: updatedBanner,
      };
      return updatedProfile;
    });
  }

  function updateCompanyLogo(updatedLogo: string) {
    setCompanyProfile((prevProfile) => {
      if (!prevProfile) return null;
      const updatedProfile = {
        ...prevProfile,
        logoUrl: updatedLogo,
      };
      return updatedProfile;
    });
  }

  function updateName(newName: string) {
    setCompanyProfile((prevProfile) => {
      if (!prevProfile) return null;
      const updatedProfile = {
        ...prevProfile,
        name: newName,
      };
      return updatedProfile;
    });
  }

  function updateDescription(newDesc: string) {
    setCompanyProfile((prevProfile) => {
      if (!prevProfile) return null;

      const updatedProfile = {
        ...prevProfile,
        description: newDesc,
      };

      return updatedProfile;
    });
  }

  function renderTagsEditor() {
    return (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          mt: '0.25rem',
          gapX: '0.25rem',
        }}>
        {companyProfile!.header.map((field: ICompanyField) => {
          if (field.type !== CompanyProfileFieldType.TAGS) {
            return null;
          }
          checkIfInfoIsMissing(field, 'usedTags');

          const { tagGroup, usedTags: selectedTags = [], id } = field;
          const tagOptions = tagGroup ? tagGroup.tags : [];

          return (
            <Box key={'tags-editor-' + id} sx={{ m: '0.25rem' }}>
              <FlexBox
                sx={{
                  width: '100%',
                  height: '2rem',
                  mb: '1rem',
                  whiteSpace: 'wrap',
                }}>
                <TagsField
                  tags={selectedTags}
                  tagOptions={tagOptions}
                  selectedTags={selectedTags}
                  updateCompanyField={(id: string, newTags: ITag[]) =>
                    updateCompanyField(Number(id), newTags)
                  }
                  id={id.toString()}
                  updateTags={(tagName: string) => {
                    const tagToRemove = selectedTags.find(
                      (tag: ITag) => tag.name === tagName,
                    );
                    if (tagToRemove) {
                      removeSelectedTag(id, tagToRemove);
                    }
                  }}
                  sx={{
                    border:
                      selectedTags.length < 1
                        ? `1px solid ${primary.warningRed}`
                        : `1px solid ${primary.natural6}`,
                  }}
                />
              </FlexBox>
            </Box>
          );
        })}
      </Box>
    );
  }

  function renderTagGroupEditor() {
    if (!companyProfile?.tagGroup) return null;

    const selectedTags = companyProfile!.usedTags ?? [];
    const tagOptions = companyProfile!.tagGroup
      ? companyProfile!.tagGroup.tags
      : [];

    return (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          mt: '0.25rem',
          gapX: '0.25rem',
        }}>
        <Box sx={{ m: '0.25rem' }}>
          <FlexBox
            sx={{
              width: '100%',
              height: '2rem',
              mb: '1rem',
              whiteSpace: 'wrap',
            }}>
            <TagsField
              tags={selectedTags}
              tagOptions={tagOptions}
              selectedTags={selectedTags}
              id={
                companyProfile?.tagGroup?.id
                  ? companyProfile?.tagGroup?.id.toString()
                  : '0'
              }
              updateCompanyTagGroup={(newTags: ITag[]) => {
                updateCompanyTagGroup(newTags);
              }}
              updateTags={(tagName: string) => {
                const tagToRemove = selectedTags.find(
                  (tag: ITag) => tag.name === tagName,
                );
                if (tagToRemove) {
                  removeSelectedTag('tagGroup', tagToRemove);
                }
              }}
              sx={{
                border:
                  selectedTags.length < 1
                    ? `1px solid ${primary.warningRed}`
                    : `1px solid ${primary.natural6}`,
              }}
            />
          </FlexBox>
        </Box>
      </Box>
    );
  }

  function renderCardMedia() {
    return (
      <Box
        sx={{
          position: 'relative',
          height: '15rem',
          display: 'flex',
        }}>
        <CardMedia
          component='img'
          src={companyProfile!.bannerUrl}
          alt='CompanyCard-Background'
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
        <Box
          sx={{
            position: 'relative',
            width: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {!isLogoLoading ? (
            <UploadImage
              // onUpload={(file) => setEditedLogo(file)}
              onUpload={(file) => uploadNewCompanyLogo(file)}
              initialSrc={companyProfile!.logoUrl}
              uploadText={'Upload Logo'}
              showButtons={false}
              isDisabled={isLoading}
              borderColor={naturalPallete.natural6}
              crop={true}
              aspectRatioHeight={1}
              aspectRatioWidth={2}
              deleteFunction={deleteLogo}
              sx={{
                width: '20rem',
                maxWidth: '20rem',
                maxHeight: '10rem',
                marginTop: '1.75rem',
              }}
              cameraIconOffset={'-7rem'}
              isLoading={isLogoLoading}
            />
          ) : (
            <Loading />
          )}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: '-1rem',
            right: '1rem',
            padding: '1rem',
          }}>
          <UploadImage
            onUpload={(file) => uploadNewCompanyBanner(file)}
            initialSrc={companyProfile!.bannerUrl}
            uploadText={'Upload banner'}
            showButtons={false}
            isDisabled={isLoading}
            borderColor={naturalPallete.natural6}
            crop={true}
            aspectRatioHeight={1}
            aspectRatioWidth={4}
            deleteFunction={deleteBanner}
            sx={{
              width: '0.5rem',
              maxWidth: '0.5rem',
              maxHeight: '0.5rem',
            }}
            cameraIconOffset={'0rem'}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    );
  }

  function renderHeaderContainer() {
    return (
      <>
        <FlexBox
          sx={{
            justifyContent: 'space-between',
            alignItems: 'start',
            flexWrap: 'noWrap',
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              flexWrap: 'wrap',
              width: '100%',
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'start',
                flexWrap: 'wrap',
                mb: '0.75rem',
                width: '100%',
              }}>
              <InputField
                value={companyProfile!.name || ''}
                placeholder={'Acterio'}
                readonly={isLoading}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  updateName(event.target.value)
                }
              />
            </Box>
            {renderTagsEditor()}
            {renderTagGroupEditor()}
          </Box>
          <FlexBox>
            <AccessLevelTagCard
              accessLevelName={companyProfile?.label || 'Unknown'}
              accessLevelColor={companyProfile?.color}
              left={'1rem'}
              height={'2rem'}
              sx={{
                display: 'inline-flex',
                whiteSpace: 'nowrap',
                mr: '1.5rem',
              }}
            />
            <FlexBox
              sx={{ color: naturalPallete.primaryDisabledFont, mr: '1.2rem' }}>
              <MoreVert />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </>
    );
  }

  function renderHeaderFieldsEditor() {
    return companyProfile!.header.map((field: ICompanyField) => {
      if (field.type !== CompanyProfileFieldType.TEXT) {
        return null;
      }
      checkIfInfoIsMissing(field, 'text');
      return (
        <FlexBox key={'text-editor-' + field.id} sx={{ columnGap: '0.5rem' }}>
          <Tooltip title={field.name}>
            <Box>
              <IconPicker
                name={field.icon}
                sx={{ color: theme.palette.primary.main }}
              />
            </Box>
          </Tooltip>

          <Box>
            <InputField
              error={field.required && !field.text}
              value={field.text || ''}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                updateCompanyField(field.id, event.target.value)
              }
              placeholder={field.name}
            />
          </Box>
        </FlexBox>
      );
    });
  }

  function revertContactChanges() {
    if (!companyProfile) return;
    setCompanyProfile({
      ...companyProfile,
      email: copyCompanyProfiles.email,
      telephone: copyCompanyProfiles.telephone,
      linkedin: copyCompanyProfiles.linkedin,
      facebook: copyCompanyProfiles.facebook,
      x: copyCompanyProfiles.x,
      instagram: copyCompanyProfiles.instagram,
    });
  }

  function renderContactsEditor() {
    return (
      <>
        <FlexBox
          sx={{
            maxWidth: '15rem',
            mx: '1rem',
            pl: '1rem',
            pr: '0.5rem',
            alignItems: 'center',
            justifyContent: 'center',
            border: `1px solid ${naturalPallete.natural6}`,
            borderRadius: '8px',
          }}>
          <PhoneIcon sx={{ mr: '0.5rem' }} />
          <EmailIcon />
          <CompanyContact deactivate={true} sx={{ alignItems: 'center' }} />
          <FlexBox
            onClick={() => setIsModalOpen(true)}
            sx={{
              justifyContent: 'center',
              alighItems: 'center',
              height: '1.5rem',
              width: '1.5rem',
              p: '1rem',

              borderRadius: '50%',
              color: naturalPallete.mainBlackFont,
              cursor: 'pointer',
              ':hover': {
                backgroundColor: theme.palette.primary.light,
              },
            }}>
            <MoreVert />
          </FlexBox>
        </FlexBox>
        <InformationModal
          isOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          primaryText='Save'
          primaryOnClick={() => setIsModalOpen(false)}
          secondaryText='Revert changes'
          secondaryOnClick={revertContactChanges}
          headerText={'Social media links & contact details'}>
          <Box sx={{ mt: '1rem' }}>
            <CompanyEditSocialMediaAndContacts />
          </Box>
        </InformationModal>
      </>
    );
  }

  if (!companyProfile) return null;

  return (
    <>
      <Card
        sx={{
          width: '100%',
          mb: '1rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderRadius: '12px',
          backgroundColor: `${primary.pureWhite}`,
          border: `1px solid ${primary.natural6}`,
        }}>
        {renderCardMedia()}
        <CardContent sx={{ pr: '0rem' }}>
          {renderHeaderContainer()}
          <Grid
            container
            sx={{
              justifyContent: 'space-between',
              alignItems: 'end',
            }}>
            <Grid item xs={12} sx={{ mr: '1rem' }}>
              <InputField
                value={companyProfile?.description || ''}
                placeholder='Description'
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  updateDescription(event.target.value)
                }
                sx={{
                  width: '100%',
                  mb: '1rem',
                }}
              />
            </Grid>

            <Grid item xs={9}>
              <FlexBox
                sx={{
                  flexWrap: 'wrap-reverse',
                  columnGap: '0.5rem',
                  rowGap: '0.5rem',
                }}>
                {renderHeaderFieldsEditor()}
              </FlexBox>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {renderContactsEditor()}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default CompanyEditHeader;
