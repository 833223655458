import React, { FunctionComponent, useEffect, useState } from 'react';
import { AppBar, Fade, Theme, Toolbar, useTheme } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { acterioTheme, black, primary } from 'theme';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import Avatar from '../common/Avatar';
import UserMenu from './UserMenu';
import BugReport from './BugReport';
import {
  adjustResponsiveness,
  getEcosystemNameFromPath,
  getPathName,
} from 'utils/utils';
import { getAccount, getEcosystemInfo } from 'utils/storage';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { Menu, SmartToy } from '@mui/icons-material';
import DigitalAdvisor from './DigitalAdvisor';
import { useMobileContext } from 'services/contexts/MobileContext';
import { Box } from '@mui/system';
import MobileNavbarPopup from './MobileNavbarPopup';

const useStyles = makeStyles((theme: Theme) => ({
  links: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '7.5rem',
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: black,
    fontSize: '0.875rem',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },

  activeLink: {
    borderBottom: `2px solid ${theme.palette.primary.dark}`,
  },

  acterioLinks: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  },

  acterioLink: {
    alignItems: 'center',
    width: '7.5rem',
    textTransform: 'uppercase',
    textDecoration: 'none',
    fontSize: '0.875rem',
    color: 'white',
    '&:hover': {
      color: primary.main,
      backgroundColor: 'transparent',
    },
    fontWeight: 600,
  },

  activeActerioLink: {
    fontWeight: 700,
    color: primary.main,
    borderBottom: `2px solid ${primary.main}`,
  },

  disabled: {
    cursor: 'default',
    pointerEvents: 'none',
  },
}));

const ECOSYSTEM_LINKS: string[] = [
  'home',
  'companies',
  'booking',
  'events',
  'resources',
  // 'forum',
  'admin',
];

const ACTERIO_LINKS: string[] = ['dashboard', 'account', 'help & support'];

interface INavbar {
  main: HTMLDivElement | null;
  showBorder: boolean;
  loading: boolean;
  ecoLogo: string | undefined;
}

const Navbar: FunctionComponent<INavbar> = (props) => {
  const { main, showBorder, loading, ecoLogo } = props;

  const { isMobile, isMenuVisible, isMenuOpen, setIsMenuOpen, menuSections } =
    useMobileContext();

  const theme = useTheme();

  const avatarSize = adjustResponsiveness(45, 28);

  const location = useLocation();
  const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showBugReport, setShowBugReport] = useState<boolean>(false);
  const [showDigitalAdvisor, setShowDigitalAdvisor] = useState<boolean>(false);
  const [currentPath, setCurrentPath] = useState<string>(
    getPathName(location.pathname),
  );
  const [inActerio, setInActerio] = useState<boolean>(
    ACTERIO_LINKS.includes(currentPath),
  );

  const classes = useStyles({ isMobile, inActerio });

  const helpAndSupportUrl = 'https://www.acterio.com/help';

  useEffect(() => {
    if (showMenu) setShowMenu(false);

    const pathName = getPathName(location.pathname);
    setInActerio(ACTERIO_LINKS.includes(pathName));
    setCurrentPath(pathName);

    const scrollTimeout = setTimeout(() => {
      if (main) main.scrollTop = 0;
    }, 200);

    return () => clearTimeout(scrollTimeout);
  }, [location]);

  function isHome(path: string, routeLen: number) {
    return path === 'home' && routeLen === 3;
  }

  function getEcosystemPath(path: string) {
    const name = getEcosystemNameFromPath(location.pathname);
    return path === 'home' ? `ecosystem/${name}` : `ecosystem/${name}/${path}`;
  }

  function getNavbarLogo() {
    if (inActerio) return '/images/logo.png';
    if (!ecoLogo) return '/images/logo.png';
    return ecoLogo;
  }

  function renderNavLinks() {
    const links = (inActerio ? ACTERIO_LINKS : ECOSYSTEM_LINKS).map(
      (path, i) => {
        if (path === 'admin' && !getEcosystemInfo().displayAdminButton) {
          return null;
        }

        if (path === 'booking' && !getEcosystemInfo().canSeeBooking) {
          return null;
        }

        const parts = location.pathname.split('/');
        const isCommunityPath =
          parts.includes('companies') || parts.includes('members');
        const isHomeRoute = isHome(path, parts.length);
        const currentPath = isCommunityPath
          ? 'members' === path || 'companies' === path
          : parts.includes(path) || isHome(path, parts.length);
        const ecosystemClasses = clsx(classes.link, {
          [classes.activeLink]: currentPath,
          [classes.disabled]:
            (parts.length === 4 && currentPath) || isHomeRoute,
        });
        const acterioClasses = clsx(classes.link, classes.acterioLink, {
          [classes.activeActerioLink]: currentPath,
        });

        if (path === 'help & support')
          return (
            <Button
              key={`link-${i}`}
              onClick={() => window.open(`${helpAndSupportUrl}`, '_blank')}
              sx={{
                alignItems: 'center',
                width: '9rem',
                textTransform: 'uppercase',
                textDecoration: 'none',
                fontSize: '0.875rem',
                color: 'white',
                fontWeight: 600,
                '&:hover': {
                  color: primary.main,
                },
              }}>
              {path}
            </Button>
          );

        return (
          <Link
            key={`link-${i}`}
            to={inActerio ? path : getEcosystemPath(path)}
            className={inActerio ? acterioClasses : ecosystemClasses}
            onClick={() => setCurrentPath(path)}>
            {path === 'members' || path === 'companies' ? 'community' : path}
          </Link>
        );
      },
    );

    return links;
  }

  function renderSuperAdminButton() {
    const { role } = getAccount();
    if (role !== 'ROLE_ADMIN') return null;
    return (
      <>
        <Button
          sx={{
            height: '3.125rem',
            width: '3.125rem',
            minWidth: '3.125rem',
            borderRadius: '50% !important',

            '& svg': {
              height: '2.188rem',
              width: '2.188rem',
            },
          }}
          onClick={() => navigate('/super-admin')}>
          <AdminPanelSettingsIcon
            style={{
              color: inActerio ? primary.light : theme.palette.primary.main,
            }}
          />
        </Button>
      </>
    );
  }

  //rip digital advisor
  function renderDigitalAdvisor() {
    return (
      <>
        <Button
          sx={{
            height: '3.125rem !important',
            width: '3.125rem !important',
            minWidth: '3.125rem !important',
            borderRadius: '50% !important',
            backgroundColor: 'transparent !important',

            '& svg': {
              height: '2.188rem',
              width: '2.188rem',
            },
          }}
          onClick={() => setShowDigitalAdvisor(!showDigitalAdvisor)}>
          <SmartToy
            style={{
              color: inActerio ? primary.light : theme.palette.primary.main,
            }}
          />
        </Button>
        <DigitalAdvisor
          open={showDigitalAdvisor}
          close={() => setShowDigitalAdvisor(false)}
        />
      </>
    );
  }

  function renderBugReport() {
    return (
      <>
        <Button
          sx={{
            height: '3.125rem',
            width: '3.125rem',
            minWidth: '3.125rem',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '& svg': {
              height: '2.188rem',
              width: '2.188rem',
            },
          }}
          onClick={() => setShowBugReport(true)}>
          <BugReportOutlinedIcon
            style={{
              color: inActerio ? primary.light : theme.palette.primary.dark,
            }}
          />
        </Button>
        <BugReport open={showBugReport} close={() => setShowBugReport(false)} />
      </>
    );
  }

  function renderAvatar() {
    const { profilePicture } = getAccount();

    return (
      <Box>
        <Button
          sx={{
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          TouchRippleProps={{
            style: {
              color: acterioTheme.soft,
            },
          }}
          onClick={() => setShowMenu(!showMenu)}>
          <Avatar
            source={profilePicture}
            height={avatarSize}
            width={avatarSize}
            radius={avatarSize}
          />
        </Button>
        <UserMenu show={showMenu} inActerio={inActerio} />
      </Box>
    );
  }

  return (
    <AppBar
      elevation={1}
      position='fixed'
      style={{
        backgroundColor: inActerio ? 'transparent' : '#FFFFFF',
      }}>
      <Toolbar
        sx={{
          padding: '0rem 1rem!important',
          borderBottom: inActerio
            ? 'none'
            : showBorder
            ? '2px solid rgba(207, 229, 231, 0.15)'
            : 'none',
          boxShadow: inActerio ? 'rgba(0, 0, 0, 0) 1.95px 1.95px 2.6px' : '',
        }}>
        {isMobile && (
          <Box width={'6rem'}>
            {isMenuVisible && (
              <Button
                sx={{
                  minWidth: 'unset',
                  width: '100%',
                }}
                onClick={() => setIsMenuOpen(true)}>
                <Menu sx={{ color: theme.palette.primary.dark }} />
              </Button>
            )}
            {isMobile && isMenuVisible && !!menuSections && (
              <MobileNavbarPopup
                isOpen={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
              />
            )}
          </Box>
        )}
        <Box
          sx={{
            marginLeft: '2rem',
            height: '2.5rem',
            width: '9.4rem',
            transition: 'width 0.3s ease-in-out',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}
          style={isMobile ? {} : !inActerio ? {} : { margin: '0rem 7rem' }}>
          {!loading && (
            <Fade in={true}>
              <img
                style={{
                  maxHeight: '100%',
                  maxWidth: '100%',
                  cursor: 'pointer',
                }}
                src={getNavbarLogo()}
                alt='logo'
                onClick={() =>
                  navigate(inActerio ? '/dashboard' : getEcosystemPath('home'))
                }
              />
            </Fade>
          )}
        </Box>
        {inActerio && !isMobile && (
          <Box sx={{ height: '100%', display: 'flex', marginRight: 'auto' }}>
            {renderNavLinks()}
          </Box>
        )}
        {!inActerio && !isMobile && (
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'start',
              marginLeft: '10rem',
            }}>
            {renderNavLinks()}
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}>
          {renderSuperAdminButton()}
          {/* {renderDigitalAdvisor()} */}
          {renderBugReport()}
          {renderAvatar()}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
