import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { ButtonBase, Theme } from '@mui/material';
import { IJourneyGoal } from 'utils/models';
import { useJourneyContext } from './JourneyContext';
import JourneyAssignMembers from './JourneyAssignMembers';
import { AddRounded } from '@mui/icons-material';
import { IJourneyPerson } from '../Admin/ManageJourneys/JourneyTemplate/JourneyTemplatePerson';
import { useNavigate, useParams } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  cont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.6rem 1rem',
    paddingRight: '0.75rem',
    height: '2.5rem',
    position: 'relative',

    '& p': {
      fontSize: '0.8rem !important',
      marginTop: '3px !important',
    },

    '& svg': {
      height: '18px',
      width: '18px',
      color: theme.palette.primary.dark,
    },

    '& button': {
      borderRadius: '4px !important',
      padding: '3px !important',
      marginTop: '2px !important',
    },
  },
  aside: {
    width: '175px',
    height: '2.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.light,
    '& svg': {
      color: theme.palette.primary.dark,
    },

    '& p': {
      textAlign: 'center',
      display: 'flex',
      alignItems: 'flex-end',
    },
  },
  pastDeadline: {
    backgroundColor: '#FF5252 !important',
    '& p': {
      color: '#FFEBEE !important',
    },
    '& svg': {
      color: '#FFEBEE !important',
      marginRight: '10px',
    },
  },
  member: {
    height: '2rem',
    width: 'auto',
    borderRadius: '50%',
  },
  members: {
    display: 'flex',
    position: 'relative',
    minWidth: '10rem',
    right: '17px',
    overflow: 'hidden',
  },
}));

interface JourneyGoalAssignedPeopleProps {
  goal: IJourneyGoal;
  isCustom: boolean | undefined;
}

const JourneyGoalAssignedPeople: FunctionComponent<
  JourneyGoalAssignedPeopleProps
> = (props) => {
  const { goal, isCustom } = props;
  const navigate = useNavigate();
  const { ecosystemName } = useParams();
  const { people } = goal;
  const classes = useStyles();
  const { putPeople } = useJourneyContext();
  const [showAssignMembers, setShowAssignMembers] = useState<boolean>(false);
  const [assignedMembers, setAssignedMembers] = useState<IJourneyPerson[]>(
    people ? people : [],
  );

  function close() {
    setShowAssignMembers(false);
  }

  function renderMembersPreview() {
    const MAX_TO_SHOW = 8;
    return assignedMembers.slice(0, MAX_TO_SHOW).map((member, i) => {
      return (
        <ButtonBase
          onClick={() =>
            navigate(`/ecosystem/${ecosystemName}/members/${member.id}`)
          }
          key={`member-${Math.random()}`}
          style={{
            position: 'relative',
            left: i * -14,
            zIndex: MAX_TO_SHOW - i,
          }}>
          <img
            title={member.fullName}
            src={member.profilePic}
            className={classes.member}
          />
        </ButtonBase>
      );
    });
  }

  return (
    <div>
      <div
        className={classes.cont}>
        <div className={classes.members}>
          <ButtonBase onClick={() => setShowAssignMembers(true)}>
            <AddRounded />
          </ButtonBase>
          {renderMembersPreview()}
        </div>
      </div>
      <JourneyAssignMembers
        goal={goal}
        open={showAssignMembers}
        assignedMembers={assignedMembers}
        setAssignedMembers={setAssignedMembers}
        save={() => {
          if (goal.id) {
            putPeople(goal.id, assignedMembers, isCustom, close);
          }
        }}
        close={close}
      />
    </div>
  );
};

export default JourneyGoalAssignedPeople;
