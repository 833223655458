import React, { FunctionComponent } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';
import {
  CompanyProfileFieldType,
  ICompanyField,
  ICompanyProfile,
} from 'utils/models';
import { primary } from 'theme';

Font.register({
  family: 'Inter',
  src: '/fonts/Inter-Regular.ttf',
});

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: primary.pureWhite,
    padding: 32,
    fontFamily: 'Inter',
  },
  flexHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'flex-end',
  },
  companyLogo: {
    height: 32,
  },
  flexIcons: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 4,
    gap: 4,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  divider: {
    display: 'flex',
    flexDirection: 'row',
    border: `1px solid ${primary.natural5}`,
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    marginTop: 8,
    marginBottom: 16,
  },
  section: {
    marginBottom: 8,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  contact: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginBottom: -2,
  },
  profilePic: {
    height: 24,
    borderRadius: '100%',
    marginRight: 2,
  },
  iconAndText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 12,
    width: '50%',
  },
  icon: {
    color: primary.main,
    height: 16,
  },
  contactIcon: {
    color: primary.main,
    height: 12,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 4,
  },
  employeeName: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  employeeTitle: {
    fontSize: 10,
    fontWeight: 'bold',
    color: primary.natural3,
  },
  mainFields: { marginTop: 16 },
  alignRight: {
    textAlign: 'right',
  },
  pageFooter: {
    textAlign: 'right',
    alignItems: 'flex-end',
    fontSize: 12,
    marginTop: 16,
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  gridItem: {
    width: '50%',
  },
  richTextDisplay: {
    lineHeight: 1.25,
    fontSize: 10,
    marginBottom: 8,
  },
  text: { fontSize: 12, fontFamily: 'Inter' },
  date: { fontSize: 10, marginTop: 8, marginBottom: 4, fontFamily: 'Inter' },
  tag: {
    fontSize: 10,
    marginBottom: 4,
    marginTop: 4,
    padding: 4,
    backgroundColor: '#f0f0f0',
    borderRadius: 4,
  },
  tagsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 4,
  },

  p: {
    lineHeight: 1.5,
    marginBottom: 10,
    marginTop: 0,
    textAlign: 'justify',
    fontFamily: 'Inter',
  },
  br: {
    marginVertical: 10,
  },
  strong: {
    fontWeight: 'bold',
  },
  em: {
    fontStyle: 'italic',
  },
  u: {
    textDecoration: 'underline',
  },
  a: {
    color: 'blue',
    textDecoration: 'underline',
  },
  h1: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 6,
    marginTop: 8,
  },
  h2: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 4,
    marginTop: 6,
  },
  h3: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 2,
    marginTop: 4,
  },
  h4: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 2,
    marginTop: 2,
  },
  h5: {
    fontSize: 8,
    fontWeight: 'bold',
    marginBottom: 2,
    marginTop: 2,
  },
  h6: {
    fontSize: 8,
    fontWeight: 'bold',
    marginBottom: 2,
    marginTop: 2,
  },
});

interface ICompanyPdf {
  companyProfile: ICompanyProfile;
}

const CompanyPdf: FunctionComponent<ICompanyPdf> = (props) => {
  const { companyProfile } = props;

  // TO DO
  // 1. Ensure font is attached and working correctly
  // 2. Rich text is not working as should

  function chunkArray(array: any[], size: number): any[] {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  }

  const handleRichText = (html: string) => {
    const output: any = [];
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    doc.body.childNodes.forEach((node) => {
      switch (node.nodeName.toLowerCase()) {
        case 'p':
          output.push(<Text style={styles.p}>{node.textContent}</Text>);
          break;
        case 'br':
          output.push(<Text style={styles.br} />);
          break;
        case 'strong':
          output.push(<Text style={styles.strong}>{node.textContent}</Text>);
          break;
        case 'em':
          output.push(<Text style={styles.em}>{node.textContent}</Text>);
          break;
        case 'u':
          output.push(<Text style={styles.u}>{node.textContent}</Text>);
          break;
        case 'h1':
          output.push(<Text style={styles.h1}>{node.textContent}</Text>);
          break;
        case 'h2':
          output.push(<Text style={styles.h2}>{node.textContent}</Text>);
          break;
        case 'h3':
          output.push(<Text style={styles.h3}>{node.textContent}</Text>);
          break;
        case 'h4':
          output.push(<Text style={styles.h4}>{node.textContent}</Text>);
          break;
        case 'h5':
          output.push(<Text style={styles.h5}>{node.textContent}</Text>);
          break;
        case 'h6':
          output.push(<Text style={styles.h6}>{node.textContent}</Text>);
          break;
        case 'a':
          output.push(<Text style={styles.a}>{node.textContent}</Text>);
          break;

        default:
          output.push(<Text>{node.textContent}</Text>);
      }
    });

    return output;
  };

  function pickIcon(icon: string | undefined) {
    let iconName;

    const iconsList = [
      'InfoRounded',
      'WorkRounded',
      'LocationOnRounded',
      'TurnedIn',
      'AssignmentIndRounded',
      'SellRounded',
      'CheckCircleRounded',
      'FlagRounded',
      'InsertDriveFileRounded',
      'ApartmentRounded',
      'EventRounded',
      'LocationSearchingRounded',
      'SourceRounded',
      'MonetizationOnRounded',
    ];

    if (icon === undefined) {
      iconName = 'InfoRounded';
    } else if (icon === 'Email') {
      iconName = 'Email';
    } else if (icon === 'Phone') {
      iconName = 'Phone';
    } else if (iconsList.includes(icon)) {
      iconName = icon;
    } else {
      iconName = 'InfoRounded';
    }

    return '/images/pdf/' + iconName + '.png';
  }

  function renderHeader() {
    return (
      <View style={styles.section}>
        <View style={styles.flexHeader}>
          <Image src={companyProfile.logoUrl} style={styles.companyLogo} />

          <View style={styles.alignRight}>
            <Text style={styles.subtitle}>{companyProfile.label}</Text>
          </View>
        </View>
        <hr style={styles.divider} />

        <View style={styles.header}>
          <View style={{ marginBottom: 16 }}>
            <Text style={styles.title}>{companyProfile.name}</Text>
          </View>

          <View style={styles.contact}>
            {companyProfile.telephone && (
              <View style={{ ...styles.iconAndText, marginLeft: 2 }}>
                <Image src='/images/pdf/Phone.png' style={styles.contactIcon} />
                <Text style={styles.text}>{companyProfile.telephone}</Text>
              </View>
            )}
            {companyProfile.email && (
              <View style={{ ...styles.iconAndText, marginLeft: -2 }}>
                <Image src='/images/pdf/Email.png' style={styles.contactIcon} />
                <Text style={styles.text}>{companyProfile.email}</Text>
              </View>
            )}
          </View>
        </View>
      </View>
    );
  }
  function renderIcons() {
    const icons = chunkArray(companyProfile.header, 1); // Change second arg to group icons differently

    return (
      <View style={styles.gridContainer}>
        {icons.map((item: ICompanyField[], i) => (
          <View style={styles.gridItem} key={i}>
            {item.map((field: ICompanyField) => {
              if (
                field.type !== CompanyProfileFieldType.TEXT ||
                field.text === undefined
              )
                return null;

              return (
                <View key={field.name}>
                  <View style={styles.flexIcons}>
                    <View style={{ paddingRight: 8 }}>
                      <Image src={pickIcon(field.icon)} style={styles.icon} />
                    </View>
                    <Text style={styles.text}>{field.text}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        ))}
      </View>
    );
  }
  function renderMainSection() {
    return (
      <View style={styles.mainFields}>
        {companyProfile.main.map((field, index) => {
          if (!field.richText || !field.name) return;

          return (
            <View key={index}>
              <View>
                <Text style={styles.sectionTitle}>{field.name}</Text>
                <Text style={styles.richTextDisplay}>
                  {handleRichText(field.richText)}
                </Text>
                <hr style={styles.divider} />
              </View>
            </View>
          );
        })}
      </View>
    );
  }

  // function renderTeam() {
  //   const people = companyProfile.companyProfileEmployeeDto || []; // This is a pickle
  //   const peopleChunks = chunkArray(people, 1);

  //   if (peopleChunks.length < 1) return null;

  //   return (
  //     <View style={styles.flexColumn}>
  //       <Text style={styles.sectionTitle}>Team</Text>
  //       <View style={styles.gridContainer}>
  //         {peopleChunks.map((chunk, i) => (
  //           <View style={styles.gridItem} key={i}>
  //             {chunk.map((person: ICompanyProfileEmployeeDTO) => {
  //               if (!person.name) return null;

  //               return (
  //                 <View
  //                   key={person.name}
  //                   style={{ marginBottom: 4, marginTop: 4 }}>
  //                   <View style={styles.flexCenter}>
  //                     <Image
  //                       src={person.profPicUrl}
  //                       style={styles.profilePic}
  //                     />
  //                     <Text style={styles.employeeName}>{person.name}</Text>
  //                     <Text style={styles.employeeTitle}>
  //                       {person.companyRole}
  //                     </Text>
  //                   </View>
  //                 </View>
  //               );
  //             })}
  //           </View>
  //         ))}
  //       </View>
  //       <hr style={styles.divider} />
  //     </View>
  //   );
  // }

  function renderSideSection() {
    const sideSectionFields = companyProfile.sidebar;

    if (!sideSectionFields || sideSectionFields.length < 1) return null;

    function renderDateViewer(field: ICompanyField, index: number) {
      return (
        <View key={index}>
          <View style={styles.flexColumn}>
            <Text style={styles.sectionTitle}>{field.name}</Text>
            <Text style={styles.date}>{field.localDate}</Text>
          </View>
          <hr style={styles.divider} />
        </View>
      );
    }
    function renderRichTextViewer(field: ICompanyField, index: number) {
      if (!field.richText) return null;
      return (
        <View key={index}>
          <Text style={styles.sectionTitle}>{field.name}</Text>
          <Text style={styles.richTextDisplay}>
            {handleRichText(field.richText)}
          </Text>
          <hr style={styles.divider} />
        </View>
      );
    }

    function renderTextViewer(field: ICompanyField, index: number) {
      return (
        <View key={index}>
          <Text style={styles.sectionTitle}>{field.name}</Text>
          <Text style={styles.text}>{field.text}</Text>
          <hr style={styles.divider} />
        </View>
      );
    }

    function renderTagViewer(field: ICompanyField, index: number) {
      if (!field.usedTags || field.usedTags.length === 0) return null;

      return (
        <View key={index} style={styles.flexColumn}>
          <Text style={styles.sectionTitle}>{field.name}</Text>
          <View style={styles.tagsContainer}>
            {field.usedTags.map((tag, tagIndex) => (
              <Text key={tagIndex} style={styles.tag}>
                {tag.name}
              </Text>
            ))}
          </View>
          <hr style={styles.divider} />
        </View>
      );
    }

    return (
      <View>
        {sideSectionFields.map((field, index) => {
          if (field.type === CompanyProfileFieldType.PEOPLE) return;
          if (field.type === CompanyProfileFieldType.FILES) return;

          if (field.type === CompanyProfileFieldType.DATE && !!field.localDate)
            return renderDateViewer(field, index);

          if (
            field.type === CompanyProfileFieldType.RICH_TEXT &&
            !!field.richText
          )
            return renderRichTextViewer(field, index);

          if (field.type === CompanyProfileFieldType.TEXT && !!field.text)
            return renderTextViewer(field, index);

          if (field.type === CompanyProfileFieldType.TAGS && !!field.usedTags)
            return renderTagViewer(field, index);

          return null;
        })}
      </View>
    );
  }

  function renderFooter() {
    return (
      <Text
        style={styles.pageFooter}
        render={({ pageNumber, totalPages }) =>
          `${pageNumber} page of ${totalPages}`
        }
        fixed
      />
    );
  }

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View>
          {renderHeader()}
          {renderIcons()}
          {renderMainSection()}
          {/* {renderTeam()} */}
          {renderSideSection()}
          {renderFooter()}
        </View>
      </Page>
    </Document>
  );
};
export default CompanyPdf;
