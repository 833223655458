import React, { FunctionComponent, useState, useEffect } from 'react';
import { IEvent } from 'utils/models';
import { IconButton, Typography, Grid, useTheme } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box } from '@mui/system';
import { getMonth } from 'utils/utils';
import Month from './Month';
import dayjs from 'dayjs';
import { useEventsContext } from 'services/contexts/Events/EventsContext';
import Loading from 'components/common/Loading';

interface CalendarViewProps {}

const CalendarView: FunctionComponent<CalendarViewProps> = () => {
  const { filteredEvents, setMonthsToShow, isCalendarLoading } =
    useEventsContext();
  const events = filteredEvents;

  const theme = useTheme();

  const [monthIndex, setMonthIndex] = useState<number>(dayjs().month());
  const [monthNumber, setMonthNumber] = useState<number>(monthIndex);
  const [calendarDays, setCaledarDays] = useState<any>(getMonth());
  const [currentYear, setCurrentYear] = useState<number>(dayjs().year());

  useEffect(() => {
    setMonthsToShow((prev) => [...prev, monthIndex]);
    setCaledarDays(getMonth(monthIndex));
  }, [monthIndex]);

  const monthsEvents = filterEventsByMonthAndYear(
    events,
    monthNumber,
    currentYear,
  );

  if (monthNumber < 0) {
    setMonthNumber(11);
    setCurrentYear((prev) => prev - 1);
  } else if (monthNumber > 11) {
    setMonthNumber(0);
    setCurrentYear((prev) => prev + 1);
  }

  function handlePreviousMonth() {
    setMonthIndex((prev) => prev - 1);
    setMonthNumber((prev) => prev - 1);
  }

  function handleNextMonth() {
    setMonthIndex((prev) => prev + 1);
    setMonthNumber((prev) => prev + 1);
  }

  function filterEventsByMonthAndYear(
    events: IEvent[],
    month: number,
    year: number,
  ): IEvent[] {
    const filteredEvents = events.filter((event: IEvent) => {
      const eventDate = new Date(event.startDate);
      return eventDate.getMonth() === month && eventDate.getFullYear() === year;
    });

    return filteredEvents;
  }

  return (
    <Grid sx={{ width: '100%' }}>
      <Box display='flex' justifyContent='space-between'>
        <Box display='flex'>
          <IconButton
            onClick={handlePreviousMonth}
            sx={{ '&:hover': { bgcolor: theme.palette.primary.light } }}>
            <ArrowBackIos sx={{ color: theme.palette.primary.dark }} />
          </IconButton>
          <Typography
            variant='h5'
            fontWeight='bold'
            fontSize='24px'
            paddingTop={1.3}>
            {`${dayjs().month(monthIndex).format('MMMM')} ${currentYear}`}
          </Typography>
          <IconButton
            onClick={handleNextMonth}
            sx={{ '&:hover': { bgcolor: theme.palette.primary.light } }}>
            <ArrowForwardIos sx={{ color: theme.palette.primary.dark }} />
          </IconButton>
        </Box>
        <Box height={20} marginLeft={-30}>
          {isCalendarLoading && <Loading height={20} />}
        </Box>
        <Box></Box>
      </Box>
      <Grid>
        <Month
          events={monthsEvents}
          calendarDays={calendarDays}
          monthIndex={monthIndex}
        />
      </Grid>
    </Grid>
  );
};

export default CalendarView;
