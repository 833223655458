import { Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import InputField from 'components/common/InputField';
import React, { FunctionComponent } from 'react';
import { Controller, FieldErrors, useFieldArray } from 'react-hook-form';
import { primary } from 'theme';
import {
  IApplicationAssessmentOption,
  IApplicationAssessmentTemplate,
} from 'utils/models';
import { FlexBox } from 'utils/styledComponents';
import { ControlButtonStyled } from '../ApplicationBuilder';
import { AddCircle, Close } from '@mui/icons-material';
import AssessmentSubsectionQuestion from './AssessmentSubsectionQuestion';

interface IAssessmentBuilderSubSections {
  control: any;
  errors: FieldErrors<IApplicationAssessmentTemplate>;
  sectionIndex: number;
  resetField: any;
  newOptions: IApplicationAssessmentOption[];
}

const AssessmentBuilderSubSections: FunctionComponent<
  IAssessmentBuilderSubSections
> = (props) => {
  const { control, sectionIndex, errors, resetField, newOptions } = props;
  const theme = useTheme();

  const {
    fields: subSectionFields,
    append: appendSubSection,
    remove: removeSubSection,
  } = useFieldArray<any>({
    name: `sections.${sectionIndex}.subSections`,
    control,
  });

  return (
    <>
      {subSectionFields.map((subSection, subSectionIndex) => {
        const errorPath =
          errors?.sections?.[sectionIndex]?.subSections?.[subSectionIndex]
            ?.name;

        return (
          <Box
            key={subSection.id}
            sx={{
              paddingLeft: '3.125rem',
              marginTop: '1.25rem',
            }}>
            <FlexBox
              sx={{
                gap: '1.563rem',
              }}>
              <FlexBox
                sx={{
                  flexDirection: 'column',
                  alignItems: 'start',
                  borderRadius: '12px',
                  boxShadow: theme.shadows[1],
                  padding: '1.563rem 1.875rem',
                  width: '100%',
                }}>
                <FlexBox
                  className={'between'}
                  sx={{
                    alignItems: 'start',
                    width: '100%',
                    gap: '1.625rem',
                  }}>
                  <Controller
                    name={`sections.${sectionIndex}.subSections.${subSectionIndex}.name`}
                    control={control}
                    rules={{
                      required: 'Please, type the question',
                    }}
                    render={({ field }) => (
                      <InputField
                        {...field}
                        onChange={(
                          e: React.ChangeEvent<
                            HTMLInputElement | HTMLTextAreaElement
                          >,
                        ) => {
                          field.onChange(e);
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            opacity:
                              sectionIndex === 0 && subSectionIndex < 2
                                ? '200%'
                                : '100%',
                            '& .MuiInputBase-input': {
                              WebkitTextFillColor: primary.mainBlackFont,
                            },
                          },
                        }}
                        helperText={errorPath && errorPath.message}
                        helperIcon={errors.sections && 'warning'}
                        error={!!errorPath}
                        maxLength={255}
                        placeholder={'Subsection name'}
                        width={'70%'}
                      />
                    )}
                  />

                  <ControlButtonStyled
                    disabled={sectionIndex === 0 && subSectionIndex < 1}
                    onClick={() => removeSubSection(subSectionIndex)}>
                    <Close />
                  </ControlButtonStyled>
                </FlexBox>
                <AssessmentSubsectionQuestion
                  resetField={resetField}
                  errors={errors}
                  control={control}
                  sectionIndex={sectionIndex}
                  subSectionIndex={subSectionIndex}
                  newOptions={newOptions}
                />
              </FlexBox>
            </FlexBox>
          </Box>
        );
      })}
      <ControlButtonStyled
        sx={{
          marginTop: '0.5rem',
          paddingLeft: '3.125rem',
        }}
        onClick={() =>
          appendSubSection({
            name: '',
            questions: [
              {
                name: '',
                options: newOptions,
              },
            ],
          })
        }>
        <AddCircle />{' '}
        <Typography
          sx={{
            marginLeft: '0.625rem',
          }}>
          Add a subsection
        </Typography>
      </ControlButtonStyled>
    </>
  );
};

export default AssessmentBuilderSubSections;
