import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Fade, Theme, Typography, useTheme } from '@mui/material';
import JourneyMenu from './JourneyMenu';
import JourneyHeader from './JourneyHeader';
import JourneyThemes from './JourneyThemes';
import { primary } from 'theme';
import { Check, PlaylistAdd, Replay } from '@mui/icons-material';
import EditWarningWindow from './EditWarningWindow';
import { useJourneyContext } from './JourneyContext';
import Loading from '../common/Loading';
import { Box } from '@mui/system';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  cont: {
    width: '56.25rem',
    maxWidth: '56.25rem',
    margin: '0 auto',
    marginTop: '4rem',
    position: 'relative',
  },
}));

interface IJourneyProps {}

const Journey: FunctionComponent<IJourneyProps> = (props) => {
  const classes = useStyles();

  const theme = useTheme();

  const {
    isLoading,
    isSmallLoading,
    isEditJourneyContext,
    isEditWarningWindowOpen,
    setIsEditWarningWindowOpen,
    revertChanges,
  } = useJourneyContext();

  function handleEditJourneyButton() {
    if (isSmallLoading || isLoading) return;
    setIsEditWarningWindowOpen(true);
  }

  function handleClose() {
    setIsEditWarningWindowOpen(false);
  }

  function renderEditGoalsButton() {
    return (
      <>
        <Button
          variant='contained'
          onClick={() => handleEditJourneyButton()}
          sx={{
            position: 'fixed',
            bgcolor: theme.palette.primary.dark,
            color: primary.pureWhite,
            bottom: 40,
            right: 80,
            zIndex: 100,
            width: 185,
            borderTopRightRadius: 5,
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
          }}>
          {isEditJourneyContext ? <Check /> : <PlaylistAdd />}
          <Typography
            sx={{
              color: primary.pureWhite,
              paddingLeft: isEditJourneyContext ? 1.5 : 1,
            }}>
            {isEditJourneyContext ? 'Save changes' : 'Custom goals'}
          </Typography>
        </Button>
        {isEditJourneyContext && (
          <Button
            variant='contained'
            onClick={() => revertChanges()}
            sx={{
              position: 'fixed',
              bgcolor: 'inherit',
              bottom: 85,
              right: 80,
              zIndex: 100,
              width: 185,
              borderTopRightRadius: 5,
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              '&:hover': {
                bgcolor: theme.palette.primary.light,
              },
            }}>
            {
              <Replay
                sx={{
                  transform: 'rotate(-45deg)',
                  color: theme.palette.primary.dark,
                }}
              />
            }
            <Typography
              sx={{ color: theme.palette.primary.dark, paddingLeft: 0.5 }}>
              {'Revert changes'}
            </Typography>
          </Button>
        )}
      </>
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Fade in={true} timeout={1000}>
      <div className={classes.root}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            flexDirection: 'row',
            minHeight: 'calc(100vh - 4.4rem)',
            marginTop: '-3rem',
          }}>
          <Box>
            <JourneyMenu />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginLeft: '6rem',
            }}>
            <div className={classes.cont}>
              <JourneyHeader />
              <JourneyThemes />
            </div>
            <div>{renderEditGoalsButton()}</div>
            {isEditWarningWindowOpen && (
              <EditWarningWindow
                open={isEditWarningWindowOpen}
                handleClose={handleClose}
              />
            )}
          </Box>
        </Box>
      </div>
    </Fade>
  );
};

export default Journey;
