import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Box } from '@mui/system';
import { PDFViewer } from '@react-pdf/renderer';
import { IApplicantDetails } from 'utils/models';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useParams } from 'react-router';
import Loading from 'components/common/Loading';
import ApplicationsListPdf from './ApplicationsListPdf';
import { APPLICATION_PROCESS_APPLICANTS, getData } from 'utils/requests';
import { Typography } from '@mui/material';
import { getListOfApplicantDetailIds } from 'utils/storage';

interface IDowloadPdf {}

const ApplicationsListPdfDownload: FunctionComponent<IDowloadPdf> = () => {
  const { ecosystemName, applicationProcessId } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [applicationsList, setApplicationsList] = useState<
    IApplicantDetails[] | null
  >(null);

  const selectedApplicantIdsList = getListOfApplicantDetailIds();

  useEffect(() => {
    fetchApplicationsList();
  }, [ecosystemName, applicationProcessId]);

  async function fetchApplicationsList() {
    if (!ecosystemName || !applicationProcessId || !selectedApplicantIdsList)
      return;
    setIsLoading(true);

    getData(APPLICATION_PROCESS_APPLICANTS, [
      { name: 'ecosystemName', value: ecosystemName },
      {
        name: 'applicationProcessId',
        value: encodeURIComponent(applicationProcessId),
      },
      {
        name: 'applicantIdsList',
        value: selectedApplicantIdsList,
      },
    ])
      .then((data) => {
        setApplicationsList(data);
        setIsLoading(false);
      })
      .catch((error: any) => {
        console.error('error', error);
        addAlert({
          type: ALERT_TYPES.ERROR,
          message: error.message,
        });
        setIsLoading(false);
      });
  }

  if (isLoading)
    return (
      <>
        <Loading marginTop={90} />
        <Typography sx={{ textAlign: 'center' }}>
          Generating PDFs... Please wait...
        </Typography>
      </>
    );

  if (!selectedApplicantIdsList)
    return (
      <>
        <Typography sx={{ textAlign: 'center', mt: '6rem' }}>
          Sorry! No applications have been selected.
        </Typography>
        <Typography sx={{ textAlign: 'center' }}>
          Please go back, and select list of applications to generate.
        </Typography>
      </>
    );

  if (!applicationsList)
    return (
      <Typography sx={{ textAlign: 'center', mt: '6rem' }}>
        Sorry! Something went wrong...
      </Typography>
    );

  return (
    <Box sx={{ marginTop: '-3rem' }}>
      <PDFViewer style={{ width: '100vw', height: '100vh' }}>
        <ApplicationsListPdf applicationsList={applicationsList} />
      </PDFViewer>
    </Box>
  );
};

export default ApplicationsListPdfDownload;
