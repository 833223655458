import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import {
  Layout,
  useApplicationContext,
} from 'services/contexts/Applications/ApplicationsContext';
import IllustrationIcon from '../IllustrationIcon';
import { Add, ViewSidebarRounded } from '@mui/icons-material';
import ListIcon from '@mui/icons-material/List';
import { naturalPallete } from 'theme';
import ApplicationsKanbanView from './ApplicationsKanbanView';
import ApplicationsListView from './ApplicationsListView';
import CommonButton from 'components/common/CommonButton';
import SearchFilter from 'components/common/SearchFilter';
import { IApplicant } from 'utils/models';
import SelectDropdown from 'components/common/SelectDropdown';
import { getEcosystemInfo } from 'utils/storage';

enum SortBy {
  NewestOnTop = 'Newest on top',
  OldestOnTop = 'Oldest on top',
}
const ApplicationsOverview = () => {
  const theme = useTheme();

  const { applicationOverviewData, setLayoutMode, layoutMode } =
    useApplicationContext();

  const [isNewColumnModalOpen, setIsNewColumnModalOpen] =
    useState<boolean>(false);

  const [applicants, setApplicants] = useState<IApplicant[]>([]);

  const [searchApplicants, setSearchApplicants] = useState<IApplicant[]>([]);

  const [applicantsToReturn, setApplicantsToReturn] = useState<IApplicant[]>(
    [],
  );

  const [availableFilters, setAvailableFilters] = useState<string[]>([]);
  const availableSortBy = ['Newest on top', 'Oldest on top'];

  const [selectedFilter, setSelectedFilter] = useState<string>('');
  const [selectedSortBy, setSelectedSortBy] = useState<SortBy>(
    SortBy.NewestOnTop,
  );

  const [isClearSearch, setIsClearSearch] = useState<boolean>(false);

  const ecosystemInfo = getEcosystemInfo();
  const { canManageApplications } = ecosystemInfo.accessLevel;

  useEffect(() => {
    if (!applicationOverviewData) return;

    const flatApplicants = applicationOverviewData.statuses.flatMap(
      (status) => status.applicants,
    );

    setAvailableFilters(createSetOfFilters(flatApplicants));
    setApplicants(flatApplicants);
  }, [applicationOverviewData]);

  useEffect(() => {
    const deepCopy = JSON.parse(JSON.stringify(applicants));

    const filteredOutApplicants = filterApplicants(deepCopy, selectedFilter);

    const sortedApplicants = sortApplicantsBy(
      filteredOutApplicants,
      selectedSortBy,
    );

    setSearchApplicants(sortedApplicants);
  }, [selectedFilter, selectedSortBy, applicants]);

  useEffect(() => {
    setIsClearSearch((prev) => !prev);
  }, [selectedFilter]);

  function createSetOfFilters(applicantsArray: IApplicant[]): string[] {
    const setOfFilters = new Set<string>();
    applicantsArray.forEach((item) => {
      setOfFilters.add(item.applicationStatus.name);
    });
    return Array.from(setOfFilters);
  }

  function sortApplicantsBy(applicants: IApplicant[], sortOrder: string) {
    return applicants.sort((a, b) => {
      const dateA = new Date(a.submitDate).getTime();
      const dateB = new Date(b.submitDate).getTime();
      if (sortOrder === 'Newest on top') {
        return dateB - dateA;
      } else {
        return dateA - dateB;
      }
    });
  }

  function filterApplicants(applicants: IApplicant[], filter: string) {
    if (!filter) return applicants;
    return applicants.filter((item) => item.applicationStatus.name === filter);
  }

  function renderHeader() {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: '2rem',
          marginY: '2rem',
        }}>
        <Typography
          variant={'h3'}
          sx={{
            fontSize: '1.875rem',
            fontWeight: 600,
            marginY: '0.625rem',
            color: `${naturalPallete.mainBlackFont}`,
          }}>
          {applicationOverviewData?.name}
        </Typography>
        <IllustrationIcon
          src='/images/placeholders/beta.png'
          alt='Beta'
          sx={{ width: '6rem', objectFit: 'cover', marginY: '0.625rem' }}
        />
      </Box>
    );
  }

  function renderLayoutIcons() {
    return (
      <Box
        sx={{
          display: 'flex',
          paddingBottom: '1rem',
          justifyContent: 'space-between',
          mb: '1rem',
          width: '100%',
        }}>
        <Box sx={{ display: 'flex', gap: '1rem', height: '2rem' }}>
          <Button
            onClick={() => setLayoutMode(Layout.Kanban)}
            sx={{
              backgroundColor:
                layoutMode === Layout.Kanban
                  ? theme.palette.primary.light
                  : naturalPallete.whiteHighlight,
              borderRadius: '18px',
            }}>
            <ViewSidebarRounded sx={{ transform: 'rotate(180deg)' }} />
          </Button>
          <Button
            onClick={() => setLayoutMode(Layout.List)}
            sx={{
              backgroundColor:
                layoutMode === Layout.List
                  ? theme.palette.primary.light
                  : naturalPallete.whiteHighlight,
              borderRadius: '18px',
            }}>
            <ListIcon />
          </Button>
        </Box>
        <Box>
          {layoutMode === 'kanban' ? (
            canManageApplications && (
              <CommonButton
                icon={<Add />}
                text={'Add column'}
                onClick={() => setIsNewColumnModalOpen(true)}
                sx={{
                  marginRight: '2rem',
                  '&:hover': {
                    color: '#13253E',
                    backgroundColor: '#EBF1F9',
                  },
                }}
              />
            )
          ) : (
            <Box sx={{ display: 'flex' }}>
              <SelectDropdown
                value={selectedFilter}
                onChange={setSelectedFilter}
                options={availableFilters}
                height='2.5rem'
                placeholder={'Filters'}
                sx={{ maxWidth: '12rem', mx: '1rem' }}
              />
              <SelectDropdown
                value={selectedSortBy}
                onChange={setSelectedSortBy}
                options={availableSortBy}
                height='2.5rem'
                placeholder={'Sort by'}
                sx={{ maxWidth: '10rem', mx: '1rem' }}
              />
              <SearchFilter
                returnDirectly
                collection={searchApplicants}
                filterOnField={[
                  'id',
                  'applicantName',
                  'companyName',
                  'submitDate',
                  'applicationStatus',
                  'email',
                ]}
                onFilter={setApplicantsToReturn}
                sx={{
                  width: '20rem',
                  height: '2.5rem',
                  mx: '1rem',
                }}
                clearInput={isClearSearch}
              />
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: '100%',
        paddingX: '2rem',
      }}>
      {renderHeader()}
      {renderLayoutIcons()}
      {!!applicationOverviewData &&
        (layoutMode === 'kanban' ? (
          <ApplicationsKanbanView
            isNewColumnModalOpen={isNewColumnModalOpen}
            setIsNewColumnModalOpen={setIsNewColumnModalOpen}
          />
        ) : (
          <ApplicationsListView applicants={applicantsToReturn} />
        ))}
    </Box>
  );
};

export default ApplicationsOverview;
