import React, { FunctionComponent, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import { EventRounded } from '@mui/icons-material';
import clsx from 'clsx';
import InputDatePicker from '../common/InputDatePicker';
import { useJourneyContext } from './JourneyContext';
import Loading from '../common/Loading';
import { StageProgress } from './JourneyProgressBar';
import { colorPallete, naturalPallete } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  cont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.6rem 1rem',
    paddingRight: '0.75rem',
    height: '2.5rem',
    position: 'relative',

    '& p': {
      fontSize: '0.8rem !important',
      marginTop: '3px !important',
    },

    '& svg': {
      height: '18px',
      width: '18px',
      color: theme.palette.primary.dark,
    },

    '& button': {
      borderRadius: '4px !important',
      padding: '3px !important',
      marginTop: '2px !important',
    },
  },
  aside: {
    width: '7.438rem',
    height: '2.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.light,
    '& svg': {
      color: theme.palette.primary.dark,
    },

    '& p': {
      textAlign: 'center',
      display: 'flex',
      alignItems: 'flex-end',
    },
  },
  pastDeadline: {
    minWidth: '5rem',
    backgroundColor: colorPallete.warning,
    '& p': {
      color: naturalPallete.natural2,
    },
    '& svg': {
      color: naturalPallete.natural2,
      marginRight: '0.2rem',
    },
  },
  pastDeadlineDone: {
    minWidth: '5rem',
    backgroundColor: '#ABEDD0',
    '& p': {
      color: naturalPallete.natural2,
    },
    '& svg': {
      color: naturalPallete.natural2,
      marginRight: '0.2rem',
    },
  },
  member: {
    height: '37px',
    width: 'auto',
    borderRadius: '50%',
  },
  members: {
    display: 'flex',
    position: 'relative',
    minWidth: '10rem',
    right: '17px',
    overflow: 'hidden',
  },
}));

interface IJourneyGoalProps {
  progress: StageProgress | undefined;
  deadline: Date | null;
  goalId: number | undefined;
  isCustom: boolean | undefined;
}

const JourneyGoalDeadline: FunctionComponent<IJourneyGoalProps> = (props) => {
  const { progress, deadline, goalId, isCustom } = props;
  const classes = useStyles();
  const { putDeadline, isSmallLoading, updatingDeadlines } =
    useJourneyContext();
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  const [deadlineDate, setDeadlineDate] = useState<Date | null>(
    deadline ? new Date(deadline) : null,
  );
  const datePickerAnchorElRef = useRef<HTMLDivElement>(null);

  function pastDeadline(deadline: Date) {
    const now = new Date();
    if (
      now.getTime() <= deadline.getTime() ||
      now.toDateString() === deadline.toDateString()
    ) {
      return false;
    } else {
      return true;
    }
  }

  async function updateDateChange(newDate: Date | null) {
    setDeadlineDate(newDate);
    setShowDatePicker(false);
    await putDeadline(newDate, goalId, isCustom);
  }

  return (
    <>
      <div
        style={{ cursor: 'pointer' }}
        ref={datePickerAnchorElRef}
        onClick={() => setShowDatePicker(!showDatePicker)}
        className={clsx(
          deadlineDate && {
            [classes.pastDeadline]:
              pastDeadline(deadlineDate) && progress !== StageProgress.FINISHED,
            [classes.pastDeadlineDone]: progress === StageProgress.FINISHED,
          },
          classes.aside,
        )}>
        {isSmallLoading && goalId && updatingDeadlines.includes(goalId) ? (
          <Loading size={25} />
        ) : (
          <Typography
            sx={{
              color: naturalPallete.natural2,
              fontWeight: '500',
              fontSize: '0.8rem',
              lineHeight: '1.313rem',
            }}>
            <>
              <EventRounded
                sx={{ color: `${naturalPallete.natural2} !important` }}
              />
              {deadlineDate !== null
                ? `${deadlineDate.getDate()}-${
                    deadlineDate.getMonth() + 1
                  }-${deadlineDate.getFullYear()}`
                : 'Set Deadline'}
            </>
          </Typography>
        )}
      </div>
      {showDatePicker && (
        <InputDatePicker
          onChange={updateDateChange}
          anchorEl={datePickerAnchorElRef.current}
        />
      )}
    </>
  );
};

export default JourneyGoalDeadline;
