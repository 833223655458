import axios from 'axios';
import { getUser, getRefreshToken, setUser } from './storage';

export interface IRequestParam {
  name: string;
  value: any;
}

// export const URL = 'http://localhost:8080/api';
export const URL = 'https://2-2-3a-dot-enablemagic.ew.r.appspot.com/api';
const TIMEOUT = 300000;

/**
 *
 * @param noAuthHeader used for login/signup ONLY
 *
 */
const instance = (noAuthHeader?: boolean) => {
  const token = getUser() ?? 'NO_USER';

  return axios.create({
    baseURL: URL,
    timeout: TIMEOUT,
    headers: noAuthHeader ? undefined : { Authorization: `Bearer ${token}` },
  });
};

const refreshTokenInstance = () => {
  const token = getRefreshToken() ?? 'NO_REFRESH_TOKEN';

  return axios.create({
    baseURL: URL,
    timeout: TIMEOUT,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateRefreshTokens = async () => {
  try {
    const res = await refreshTokenInstance().get(REFRESH_TOKEN_ROUTE);
    const userData = res.data;
    setUser(userData.access_token, userData.refresh_token);
  } catch (e) {
    console.error('GET REQUEST ERROR: ', e);
    throw new Error(getErrorMessage(e));
  }
};

export const getData = async (
  route: string,
  params?: IRequestParam[],
  noAuthHeader?: boolean,
) => {
  try {
    const res = await instance(noAuthHeader).get(
      params ? buildPath(route, params) : route,
    );
    return await res.data;
  } catch (e) {
    console.error('GET REQUEST ERROR: ', e);
    throw new Error(getErrorMessage(e));
  }
};

export const postData = async (
  route: string,
  params?: IRequestParam[],
  body?: any,
  config?: any,
) => {
  try {
    const res = await instance().post(
      params ? buildPath(route, params) : route,
      body,
      config,
    );

    return await res.data;
  } catch (e: any) {
    console.error('POST REQUEST ERROR: ', e);
    throw new Error(getErrorMessage(e));
  }
};

export const patchData = async (
  route: string,
  params?: IRequestParam[],
  body?: any,
  config?: any,
) => {
  try {
    const res = await instance().patch(
      params ? buildPath(route, params) : route,
      body,
      config,
    );

    return await res.data;
  } catch (e: any) {
    console.error('PATCH REQUEST ERROR: ', e);
    throw new Error(getErrorMessage(e));
  }
};

export const deleteData = async (route: string, params?: IRequestParam[]) => {
  try {
    const res = await instance().delete(
      params ? buildPath(route, params) : route,
    );

    return await res.data;
  } catch (e) {
    console.error('DELETE REQUEST ERROR: ', e);
    throw new Error(getErrorMessage(e));
  }
};

export const putData = async (
  route: string,
  params?: IRequestParam[],
  body?: any,
  config?: any,
  noAuthHeader?: boolean,
) => {
  try {
    const res = await instance(noAuthHeader).put(
      params ? buildPath(route, params) : route,
      body,
      config,
    );

    return await res.data;
  } catch (e) {
    console.error('PUT REQUEST ERROR: ', e);
    throw new Error(getErrorMessage(e));
  }
};

export const putFormData = async (
  route: string,
  body: any,
  noAuthHeader?: boolean,
) => {
  try {
    const res = await instance(noAuthHeader).putForm(route, body);
    return await res.data;
  } catch (e) {
    console.error('POST FORM REQUEST ERROR: ', e);
    throw new Error(getErrorMessage(e));
  }
};

export const postFormData = async (
  route: string,
  body: any,
  noAuthHeader?: boolean,
) => {
  try {
    const res = await instance(noAuthHeader).postForm(route, body);
    return await res.data;
  } catch (e) {
    console.error('POST FORM REQUEST ERROR: ', e);
    throw new Error(getErrorMessage(e));
  }
};

export const patchFormData = async (
  route: string,
  body: any,
  noAuthHeader?: boolean,
) => {
  try {
    const res = await instance(noAuthHeader).patchForm(route, body);
    return await res.data;
  } catch (e) {
    console.error('POST FORM REQUEST ERROR: ', e);
    throw new Error(getErrorMessage(e));
  }
};

export const postDataWithoutAuth = async (
  route: string,
  params?: IRequestParam[],
  body?: any,
  config?: any,
) => {
  try {
    const res = await instance(true).post(
      params ? buildPath(route, params) : route,
      body,
      config,
    );

    return await res.data;
  } catch (e: any) {
    console.error('POST REQUEST ERROR: ', e);
    throw new Error(getErrorMessage(e));
  }
};

const getErrorMessage = (errorRequest: any) => {
  if (!errorRequest.response) {
    return errorRequest.message;
  }

  const { data } = errorRequest.response;
  return data || errorRequest.message;
};

const buildPath = (route: string, params: IRequestParam[]) => {
  return `${route}?${params
    .map(
      ({ name, value }, i) =>
        `${i === 0 ? '' : '&'}${cleanParam(name)}=${value}`,
    )
    .join('')}`;
};

const cleanParam = (param: string) => {
  return param.replaceAll('%20', ' ');
};

// GET data routes
export const LOGIN_ROUTE = '/login';
export const DASHBOARD_ROUTE = '/dashboard';
export const HOME_ROUTE = '/home';
export const ANNOUNCEMENTS_ROUTE = '/announcements';
export const ACCOUNT_ROUTE = '/account';
export const ACCOUNT_NOTIFICATION_SETTINGS_ROUTE = `${ACCOUNT_ROUTE}/email-notifications-settings`;
export const ACCOUNT_PROFILE_PIC_ROUTE = `${ACCOUNT_ROUTE}/profile-pic`;
export const PEOPLE_ROUTE = '/people';
export const PROFILE_ROUTE = '/profile';
export const EVENTS_ROUTE = '/calendar/events';
export const EVENTS_BY_DATE_ROUTE = '/calendar/events/monthly';
export const COMPANIES_ROUTE = '/companies';
export const COMPANY_ROUTE = '/company';
export const COMPANY_WORKSPACE_ROUTE = '/company-profile';
export const COMPANY_PROFILE_PRIVATE_ROUTE = '/company-profile/private';
export const ACCESS_CONTROL_ROUTE = '/access-control';
export const ACCESS_ADD_LEVEL_ROUTE = `${ACCESS_CONTROL_ROUTE}/add-access-level`;
export const RESOURCE_ROUTE = '/resources';
export const REGISTER_USER_ROUTE = '/register-new-user';
export const ECOADMIN_ROUTE = '/ecoadmin';
export const ECOADMIN_PENDING_INVITES_ROUTE = `${ECOADMIN_ROUTE}/pending-invites`;
export const TAG_GROUPS_ROUTE = `${ECOADMIN_ROUTE}/tag-groups`;
export const GENERATE_EMAIL_LIST_ROUTE = `${ECOADMIN_ROUTE}/generate-email-list`;
export const ECOSYSTEM_DATA_ROUTE = `${HOME_ROUTE}/ecosystem-data`;
export const SEARCH_ROUTE = '/search';
export const JOURNEY_TEMPLATES_ROUTE = '/journey-templates';
export const JOURNEY_TEMPLATES_SPECIFIC_ROUTE = '/journey-templates-specific';
export const JOURNEY_ASSIGN_COMPANY_DATA_ROUTE = '/journey-assign-company-data';
export const DIGITAL_ASSISTANT_ROUTE = '/search/digital-advisor';
export const JOURNEY_COMPANY_PROFILE_ROUTE = '/company-profile/journey';
export const CUSTOM_COMPANY_PROFILE_ROUTE = '/custom-company-profile-template';
export const COMPANY_TEMPLATE_SPECIFIC = `${CUSTOM_COMPANY_PROFILE_ROUTE}/specific`;
export const COMPANY_TEMPLATE_NAME_ID = `${CUSTOM_COMPANY_PROFILE_ROUTE}/name-and-id`;
export const SUPER_ADMIN_ROUTE = '/admin';
export const SUPER_ADMIN_DISABLED_ECOSYSTEMS = `${SUPER_ADMIN_ROUTE}/disable-ecosystem`;
export const SUPER_ADMIN_GENERATE_METRICS = `${SUPER_ADMIN_ROUTE}/generate-metrics`;
export const SUPER_ADMIN_BUG_REPORTS = `${SUPER_ADMIN_ROUTE}/bug-reports`;
export const JOURNEY_COMPANY_EMPLOYEES = `${JOURNEY_COMPANY_PROFILE_ROUTE}/employees`;
export const MEETING_AVAILABILITY_ROUTE = '/meeting/availability';
export const MEETING_PEOPLE_ROUTE = '/meeting/people';
export const MEETING_USER_AVAILABILITY_ROUTE = '/meeting/availability/user';
export const MEETING_USER_REQUESTS_ROUTE = '/meeting/pending-requests/user';
export const MEETING_MY_REQUESTS_ROUTE = '/meeting/my-requests';
export const MEETING_UPCOMING_MEETINGS_ROUTE = '/meeting/my-accepted-requests';
export const MEETING_UPCOMING_ATTENDING_MEETINGS_ROUTE =
  '/meeting/active-requests/user';
export const AUTOCOMPLETE_LOCATION = '/location/autocomplete';
export const REDIRECT_URI = `${window.location.origin}/booking/calendar/sync`;
export const BOOKING_ROOM_AVAILABILITY = '/booking/room/availability';
export const BOOKING_ROOMS = '/booking/rooms';
export const BOOKING_ROOM_UPCOMING = '/booking/room/upcoming';
export const BOOKING_ROOM_IS_AVAILABILITY_SET =
  'booking/room/is-availability-set';
export const BOOKING_CONNECT_CALENDAR_DISPLAY_POPUP =
  '/booking/connected-calendar/display-popup';
export const REFRESH_TOKEN_ROUTE = '/refresh-token';
export const COMPANY_OVERVIEW = 'companies/company-overview';
export const APPLICATIONS_PROCESSES = '/applications/processes';
export const APPLICATIONS_PROCESS_OVERVIEW = '/applications/process/overview';
export const APPLICATIONS_PROCESS_APPLICANT = '/applications/process/applicant';
export const APPLICANT_REVIEWS = '/applications/process/applicant/reviews';
export const COLOR_PALETTES = '/ecoadmin/color-palette';
export const CURRENT_COLOR_PALETTE = '/ecoadmin/color-palette/ecosystem';
export const COMPANY_LABEL_SETTINGS_ROUTE = '/ecoadmin/company-labels';
export const APPLICATION_ASSESSMENT_TEMPLATES_BASIC =
  '/applications/assessment-templates/basic';
export const APPLICATION_ASSESSMENT_REPORTS = '/applications/reports';
export const APPLICATION_ASSESSMENT_REPORTS_CARDS =
  '/applications/reports/cards';
export const APPLICATION_ASSESSMENT_REPORT_OVERVIEW =
  '/applications/report/applicants/overview';
export const APPLICATION_ASSESSMENT_REPORT_DETAILS =
  '/applications/report/applicant/details';
export const APPLICATION_ASSESSMENTS = '/applications/assessments';
export const APPLICATION_ASSESSMENTS_APPLICANT =
  '/applications/assessments/applicant';
export const APPLICATION_PROCESS_APPLICANTS =
  '/applications/process/applicants';

// POST data routes
export const PASSWORD_REST_EMAIL_ROUTE = '/send-reset-password-email';
export const UPDATE_PASSWORD_ROUTE = '/update-password';
export const COMPANY_REGISTER_NEW = '/register-company';
export const SUBMIT_BUG_REPORT_ROUTE = '/submit-bug-report';
export const REQUEST_TO_JOIN_ECOSYSTEM_ROUTE = '/request-to-join-ecosystem';
export const JOIN_BY_INVITE_ROUTE = '/join-by-invite';
export const HOME_NEW_ANNOUNCEMENT_ROUTE = `${HOME_ROUTE}/announcement`;
export const HOME_NEW_COMMENT_ROUTE = '/announcement/comment';
export const CALENDAR_NEW_EVENT_ROUTE = '/calendar/event';
export const EVENT_ROUTE = '/calendar/event';
export const ECOADMIN_HANDLE_JOIN_REQUEST_ROUTE = `${ECOADMIN_ROUTE}/handle-join-request`;
export const ECOADMIN_INVITE_ROUTE = `${ECOADMIN_ROUTE}/invite`;
export const ECOADMIN_INVITE_LINK_ROUTE = `${ECOADMIN_ROUTE}/invite-link`;
export const ECOADMIN_SAVE_KNOWLEDGE_LABELS_ROUTE = `${ECOADMIN_ROUTE}/save-knowledge-labels`;
export const ECOADMIN_TRANSFER_COMPANY_OWNERSHIP_ROUTE = `${ECOADMIN_ROUTE}/transfer-company-ownership`;
export const ACCOUNT_UPLOAD_PIC_ROUTE = `${ACCOUNT_ROUTE}/upload-pic`;
export const ACCOUNT_VERIFY_EMAIL_ROUTE = `${ACCOUNT_ROUTE}/send-verification-email`;
export const COMPANY_NEW_NOTE_ROUTE = '/company-manager/new-note';
export const COMPANY_ADD_GOAL_ROUTE = '/company-manager/add-goal';
export const COMPANY_ADD_PROBLEM_ROUTE = '/company-manager/problem';
export const COMPANY_ADD_SOLUTION_ROUTE = '/company-manager/solution';
export const COMPANY_ADD_MARKET_ROUTE = '/company-manager/market';
export const COMPANY_ADD_RESOURCE_ROUTE = '/company-manager/save-resource-link';
export const COMPANY_UPDATE_PRIV_DATA_ROUTE = '/submit-private-company-data';
export const COMPANY_UPDATE_TEAM_ROUTE = '/submit-team-data';
export const COMPANY_UPDATE_INFO_ROUTE = '/submit-company-general-information';
export const COMPANY_INVITE_MEMBER_ROUTE = '/company-manager/invite';
export const COMPANY_UPLOAD_VIDEO_ROUTE = '/company-manager/request-signed-url';
export const COMPANY_FIND_DOCUMENT = '/company-manager/find-document';
export const COMPANY_UPLOAD_DOC_ROUTE =
  '/company-manager/request-upload-shared-doc';
export const ACCESS_CONTROL_CAN_UPLOAD_ADMIN_DOCS_ROUTE =
  '/access-control/can-upload-admin-docs';
export const ACCESS_CONTROL_CAN_MANAGE_USERS_ROUTE =
  '/access-control/can-manage-users';
export const ACCESS_CONTROL_CAN_EDIT_MEMBER_PROFILES_ROUTE =
  '/access-control/can-edit-member-profiles';
export const ACCESS_CONTROL_CAN_CUSTOMIZE_ECO_ROUTE =
  '/access-control/can-customize-ecosystem-display';
export const ACCESS_CONTROL_CAN_ADD_RESOURCE_ROUTE =
  '/access-control/access-for-resource-can-add-resource';
export const ACCESS_CONTROL_CAN_MANAGE_ACCESS_REQUESTS_ROUTE =
  '/access-control/access-for-management-can-manage-access-request';
export const ACCESS_CONTROL_CAN_MANAGE_ACCESS_LEVELS_ROUTE =
  '/access-control/access-for-management-can-edit-access-levels';
export const ACCESS_CONTROL_CAN_SEE_PRIVATE_COMPANY_PROFILE_ROUTE =
  '/access-control/access-for-company-page-to-see-private-profile';
export const ACCESS_CONTROL_CAN_EDIT_COMPANY_OWN_PROFILE_ROUTE =
  '/access-control/access-for-company-page-edit-own-company-profile';
export const ACCESS_CONTROL_CAN_INVITE_COMPANY_USERS =
  '/access-control/can-companies-invite-users';
export const ACCESS_CONTROL_CAN_DELETE_COMPANY_PROFILE_ROUTE =
  '/access-control/access-for-company-page-delete-company-profile';
export const ACCESS_CONTROL_CAN_CREATE_COMPANY_PROFILE_ROUTE =
  '/access-control/access-for-company-page-create-company-profile';
export const ACCESS_CONTROL_CAN_MANAGE_JOURNEYS_ROUTE =
  '/access-control/can-manage-journeys';
export const ACCESS_CONTROL_CAN_MANAGE_COMPANY_TEMPLATES_ROUTE =
  '/access-control/can-manage-company-templates';
export const ACCESS_CONTROL_MANAGE_ANNOUNCEMENTS =
  '/access-control/manage-announcements';
export const ACCESS_CONTROL_MANAGE_EVENTS = '/access-control/manage-events';
export const ASSIGN_JOURNEY_TEMPLATE_TO_COMPANIES_ROUTE =
  '/assign-journey-template-to-companies';
export const JOURNEY_COMPANY_SET_GOAL_PROGRESS_ROUTE =
  '/company-profile/journey-set-goal';
export const JOURNEY_COMPANY_SET_CUSTOM_GOAL_PROGRESS_ROUTE =
  '/company-profile/journey-set-custom-goal-progress';
export const JOURNEY_COMPANY_SET_CUSTOM_GOAL_RESOURCES =
  '/company-profile/journey-set-custom-goal-resources';
export const SUPER_ADMIN_CREATE_ECOSYSTEM = `${SUPER_ADMIN_ROUTE}/new-ecosystem`;
export const JOURNEY_SET_LOCK_STATUS = 'journey-stage-lock-status';
export const SUPER_ADMINN_TOGGLE_ECOSYSTEM_STATUS = `${SUPER_ADMIN_ROUTE}/disable-ecosystem`;
export const MEETING_BOOK_ROUTE = '/meeting/book';
export const ACCESS_CONTROL_CAN_SET_AVAILABILITY_ROUTE =
  '/access-control/can-set-availability';
export const ACCESS_CONTROL_CAN_SCHEDULE_MEETINGS_ROUTE =
  '/access-control/can-schedule-meetings';
('/access-control/can-schedule-meetings');
export const BOOKING_PEOPLE_CALENDAR = '/booking/people/calendar';
export const BOOKING_ROOM_INTERGRATION = '/booking/room/token-authentication';
export const BOOKING_ROOM_MANAGE = '/booking/room/manage';
export const BOOKING_ROOM_BOOK_ROUTE = '/booking/room/book';
export const ACCESS_CONTROL_CAN_SET_ROOM_AVAILABILITY_ROUTE =
  '/access-control/can-set-room-availability';
export const ACCESS_CONTROL_CAN_BOOK_ROOMS = '/access-control/can-book-rooms';
export const BOOKING_ROOM_CALENDAR = '/booking/room/calendar/';
export const APPLICATIONS_PROCESS = '/applications/process';
export const APPLICATIONS_PROCESS_DUPLICATE = '/applications/process/duplicate';
export const APPLICATIONS_APPLY = '/applications/apply';
export const APPLICATION_STATUS_ROUTE = '/applications/status';
export const ACCESS_CONTROL_MANAGE_APPLICATIONS =
  '/access-control/manage-applications';
export const ACCESS_CONTROL_REVIEW_APPLICANTS =
  '/access-control/review-applicants';
export const COMPANY_PROFILE_UPLOAD_ROUTE = '/company-profile/upload';

// PATCH data routes
export const ACCOUNT_REGISTER_CONTENT_ROUTE = `${ACCOUNT_ROUTE}/register_content`;
export const ACCOUNT_SAVE_INFO = `${ACCOUNT_ROUTE}/update-account-information`;
export const ACCOUNT_CHANGE_PASSWORD_ROUTE = `${ACCOUNT_ROUTE}/change-password`;
export const ACCOUNT_UPDATE_TIMEZONE_ROUTE = `${ACCOUNT_ROUTE}/update-timezone`;
export const PROFILE_UPDATE_ROUTE = `${PROFILE_ROUTE}/update`;
export const HOME_PIN_ANNOUNCEMENT_ROUTE = `${HOME_ROUTE}/pin-announcement`;
export const ECOADMIN_ADD_GOOGLE_CALENDAR_ROUTE = `${ECOADMIN_ROUTE}/add-new-calendar`;
export const ECOADMIN_EDIT_COLOR_ROUTE = `${ECOADMIN_ROUTE}/edit-color`;
export const COMPANY_UPDATE_NOTE_ROUTE = '/company-manager/update-note';
export const COMPANY_UPDATE_GOAL_ROUTE = '/company-manager/update-goal';
export const COMPANY_ACHIEVED_GOAL_ROUTE = '/company-manager/achieved-goal';
export const COMPANY_DELETE_SHARED_DOC_ROUTE =
  '/company-manager/delete-shared-doc';
export const COMPANY_UPDATE_ABOUT_US_ROUTE = '/submit-about-us-data';
export const APPLICATION_PROCESS_APPLICANT = '/applications/process/applicant';
export const COMPANY_LABEL_AND_COLOR_ROUTE = '/ecoadmin/company-label';
export const APPLICATION_START_ASSESSMENT =
  '/applications/status/assessment-template/start';
export const APPLICATION_STOP_ASSESSMENT =
  '/applications/status/assessment-template/stop';

// PUT data routes
export const ECOADMIN_UPLOAD_LOGO_ROUTE = `${ECOADMIN_ROUTE}/upload-logo`;
export const ACCESS_CONTROL_MOVE_USER_ROUTE = `${ACCESS_CONTROL_ROUTE}/move-user-to-group`;
export const ACCESS_CONTROL_EDIT_LEVEL_ROUTE = `${ACCESS_CONTROL_ROUTE}/edit-access-level`;
export const ACCESS_CONTROL_UPDATE_DEFAULT_LEVEL_ROUTE = `${ACCESS_CONTROL_ROUTE}/update-default-access-level`;
export const ACCOUNT_CHANGE_EMAIL_NOTIFICATIONS_ROUTE = `${ACCOUNT_ROUTE}/email-notifications-settings`;
export const COMPANY_SAVE_KPI_ROUTE = '/save-kpi';
export const JOURNEY_TEMPLATE_SAVE_ROUTE = '/journey-template-save';
export const JOURNEY_SET_DEADLINE_ROUTE = `${COMPANY_WORKSPACE_ROUTE}/journey-set-deadline`;
export const JOURNEY_SET_CUSTOM_DEADLINE_ROUTE = `${COMPANY_WORKSPACE_ROUTE}/journey-set-custom-goal-deadline`;
export const JOURNEY_SET_PEOPLE_ROUTE = `${COMPANY_WORKSPACE_ROUTE}/journey-set-people`;
export const ECOADMIN_UPDATE_TAG_GROUP = `${ECOADMIN_ROUTE}/update-tag-group`;
export const JOURNEY_SET_CUSTOM_PEOPLE_ROUTE = `${COMPANY_WORKSPACE_ROUTE}/journey-set-custom-goal-people`;
export const JOURNEY_UPDATE_STAGE_ROUTE =
  '/company-profile/journey-update-stage';
export const EVENT_ATTEND_ROUTE = '/calendar/event/attend';
export const MEETING_CANCEL_USER = '/meeting/cancel/user';
export const MEETING_CANCEL_MENTOR = '/meeting/cancel/mentor';
export const MEETING_HANDLE_REQUEST = 'meeting/requests/user';
export const APPLICATIONS_PROCESS_PAUSE = '/applications/process/pause';
export const APPLICATIONS_PROCESS_START_NOW = '/applications/process/start-now';
export const RATE_APPLICANT = '/applications/process/applicant/review';
export const APPLICATION_PROCESS_EDIT = '/applications/process';
export const APPLICATION_ASSESSMENT_TEMPLATE =
  '/applications/assessment-template';
export const COMPANY_LOGO_ROUTE = '/company/logo';
export const COMPANY_BANNER_ROUTE = '/company/banner';
export const COMPANY_PUT_WORKSPACE_SECTION_ROUTE =
  '/company-manager/workspace-sections';

// DELETE data routes
export const HOME_DELETE_ANNOUNCEMENT_ROUTE = HOME_NEW_ANNOUNCEMENT_ROUTE;
export const HOME_DELETE_EVENT_ROUTE = CALENDAR_NEW_EVENT_ROUTE;
export const ECOADMIN_DELETE_USER_ROUTE = `${ECOADMIN_ROUTE}/delete-ecosystem-user`;
export const ECOADMIN_DELETE_GOOGLE_CALENDAR_ROUTE = `${ECOADMIN_ROUTE}/delete-google-calendar`;
export const ACCESS_CONTROL_DELETE_LEVEL_ROUTE = `${ACCESS_CONTROL_ROUTE}/delete-access-level`;
export const COMPANY_DELETE_KPI_ROUTE = '/delete-kpi';
export const COMPANY_DELETE_MEMBER_ROUTE = '/company-manager/remove-member';
export const COMPANY_DELETE_NOTE_ROUTE = '/company-manager/delete-note';
export const COMPANY_DELETE_GOAL_ROUTE = '/company-manager/delete-goal';
export const COMPANY_DELETE_FILE_ROUTE = '/company-manager/delete-file';
export const COMPANY_DELETE_RESOURCE_ROUTE =
  '/company-manager/delete-resource-link';
export const JOURNEY_TEMPLATES_DELETE_ROUTE = '/journey-templates-delete';
export const SUPER_ADMIN_DELETE_BUG_REPORT = `${SUPER_ADMIN_ROUTE}/delete-bug-report`;
export const ACCOUNT_DELETE = 'account/delete';
export const EVENT_DELETE_IMAGE_ROUTE = 'calendar/event/image';
export const BOOKING_ROOM_DELETE_CALENDAR =
  '/booking/room/availability/calendar';
export const JOURNEY_UNASSIGN_JOURNEYS =
  '/unassign-journey-template-to-companies';
export const APPLICATION_ASSESSMENT_REPORT = '/applications/report';
export const ECOADMIN_PENDING_INVITES_DELETE_ROUTE = '/ecoadmin/pending-invite';
